import { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    // Load the Google Analytics script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-G5DBYG6HGF";
    document.head.appendChild(script);

    // Initialize the Google Analytics script
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-G5DBYG6HGF");
    };

    return () => {
      // Cleanup the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default GoogleAnalytics;
