import React, { useState, useEffect, useRef } from "react";
import "./review.css";

const ReviewScroller = ({ reviews }) => {
  const scrollRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isPaused && scrollRef.current) {
        const maxScrollLeft =
          scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

        if (scrollPosition >= maxScrollLeft) {
          setScrollPosition(0); // Reset to start if at the end
        } else {
          setScrollPosition((prevPos) => prevPos + 2); // Slow scroll increment
        }
      }
    }, 50); // Lower interval time for more continuous scroll

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [scrollPosition, isPaused]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  // Handlers to pause and resume scrolling on mouse events
  const handleMouseOver = () => {
    setIsPaused(true);
  };

  const handleMouseOut = () => {
    setIsPaused(false);
  };

  // Pause scrolling when an in-page link is clicked and resume after a delay
  useEffect(() => {
    const handleLinkClick = () => {
      setIsPaused(true);

      // Resume scrolling after 1 second (adjust the delay as needed)
      setTimeout(() => {
        setIsPaused(false);
      }, 1000);
    };

    document.querySelectorAll('a[href^="#"]').forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, []);

  return (
    <div className="ne-r-p">
      <div
        className="r-v-cnt"
        ref={scrollRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {reviews.map((review, index) => (
          <div className="r-v-rd" key={index}>
            <img
              className="r-v-icn"
              src={review.icon}
              alt={`Icon${index + 1}`}
            />
            <p className="r-v-xt">{review.text}</p>
            <div className="r-v-r-m">
              <img
                className="r-v-pi"
                src={review.profileImage}
                alt={`Profile${index + 1}`}
              />
              <p className="r-v-r">{review.reviewer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewScroller;
