import React from "react";
import ReviewScroller from "./ReviewScroller";
import icon1 from "./components/trophy.svg";
import profimg from "./components/man7.svg";
import fi from "./components/man5.svg";
import Pics3 from "./components/man1.svg";
import wmn from "./components/wmn2.svg";
import m4 from "./components/man4.svg";
import m3 from "./components/man3.svg";
import m2 from "./components/man2.svg";
import prof from "./components/man6.svg";
const reviews = [
  {
    icon: icon1,
    profileImage: m4,
    text: "Used his service twice so far and i can say he's reliable, plus his delivery is very fast",
    reviewer: "Folorunsho D.",
  },
  {
    icon: icon1,
    profileImage: fi,
    text: "It has provided me easy access to the TradingView paid service",
    reviewer: "Fatai I.",
  },

  {
    icon: icon1,
    profileImage: Pics3,
    text: "I love your service",
    reviewer: "Lanrey",
  },
  {
    icon: icon1,
    profileImage: profimg,
    text: "Outstanding",
    reviewer: "Ebuka",
  },
  {
    icon: icon1,
    profileImage: prof,
    text: "It is very nice and economical",
    reviewer: "Abegunde JT",
  },
  {
    icon: icon1,
    profileImage: m3,
    text: "He's very fast and delivers almost immediately",
    reviewer: "Chameleon",
  },
  {
    icon: icon1,
    profileImage: m2,
    text: "Love it, reliable buh a bit expensive",
    reviewer: "The Alchemist",
  },
  {
    icon: icon1,
    profileImage: wmn,
    text: "Very good",
    reviewer: "Carl V.",
  },
];

function Review() {
  return (
    <div className="rs-v">
      <span>Reviews from our clients</span>
      <ReviewScroller reviews={reviews} />
    </div>
  );
}

export default Review;
