import "./msn.css";
import ease from "./components/coin-stack.svg";
import access from "./components/card-tick.svg";
import secure from "./components/lock.svg";
import support from "./components/support.svg";

function About() {
  return (
    <>
      <div className="msn">
        <div className="msn-pm">
          <p className="pm">Why should you choose us ?</p>
          <div className="pm1">
            We are dedicated to bridging the financial gap for Nigerians amidst
            the naira card ban, we specialize in assisting individuals in
            purchasing and subscribing to Tradingview plans. With the high
            dollar rate , we also help you find a partner you can split the
            bills with.
          </div>
        </div>
        <div className="msn-crd-all">
          <div className="msn-crd">
            <div className="crd dw-lf">
              <img src={ease} alt="" />
              <p className="crd-pm">Ease of Purchase</p>
              <p className="crd-pm1">
                Get the most, with affordable prices and offers on your purchase
              </p>
            </div>

            <div className="crd ">
              <img src={secure} alt="" />
              <p className="crd-pm">Secure and Reliable</p>
              <p className="crd-pm1">
                We play no part in 3rd party infringement, but prioritize data
                privacy and security.
              </p>
            </div>
          </div>

          <div className="msn-crd">
            <div className="crd dw-rg">
              <img src={access} alt="" />
              <p className="crd-pm">Unlimited Access</p>
              <p className="crd-pm1">
                Boycott card issues and get unrestricted access to a tradingview
                plans
              </p>
            </div>
            <div className="crd ">
              <img src={support} alt="" />
              <p className="crd-pm">24/7 Customer Support</p>
              <p className="crd-pm1">
                Round the clock support for our new and returning customers
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
