import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import "./DarkModeToggle.css";

const DarkModeToggle = () => {
  const prefersDarkMode = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (darkMode) {
      document.body.classList.add("dark-mode");
      htmlElement.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
      htmlElement.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => setDarkMode(e.matches);

    mediaQuery.addListener(handleChange);

    return () => {
      mediaQuery.removeListener(handleChange);
    };
  }, []);

  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="dark-mode-toggle-container" onClick={handleToggle}>
      <FontAwesomeIcon
        className={`icon ${darkMode ? "toggle-right " : "toggle-left"}`}
        icon={darkMode ? faSun : faMoon}
      />

      <button
        className={`dark-mode-toggle ${
          darkMode ? "toggle-right" : "toggle-left"
        }`}
      >
        <FontAwesomeIcon
          className={`icon ${darkMode ? "icon-dark" : "icon-light"}`}
          icon={darkMode ? faMoon : faSun}
        />
      </button>
    </div>
  );
};

export default DarkModeToggle;
