import quest from "./components/Questions-amico.png";
import "./cospo.css";

function CoSponsor() {
  return (
    <>
      <div className="sp-nr">
        <img src={quest} alt="" />
        <div className="sp-co">
          <p className="co">Co-sponsoring </p>
          <p className="co1">
            In addition to our services, Our co-sponsorship offer helps find a
            partner to split the costs. It is a cost sharing opportunity with
            the intent to ease the burden of the purchase through partner
            matching particularly in light of recent high exchange rate
          </p>
        </div>
      </div>
    </>
  );
}
export default CoSponsor;
