import thnlogo from "./components/thnigeria.svg";
import showMenu from "./components/mingcute_menu-fill.svg";
import cancelMenu from "./components/cancel.svg";
import trade from "./components/trading.png";
import "./header.css";
import React, { useState, useEffect, useRef } from "react";
//import { useLocation } from "react-router-dom";
import About from "./About";
import CoSponsor from "./Co-sponsor";
import Pricing from "./Pricing";
import Review from "./Review";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import GoogleAnalytics from "./GoogleAnalytics";

function Home() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref to track the dropdown

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  // Hook to close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    // Add event listener to detect clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <GoogleAnalytics />
      <Helmet>
        <title>TradingView Hub Nigeria</title>
        <meta
          name="description"
          content="We are dedicated to leveling the playing field for every trader out
            there..."
        />
        <meta
          name="keywords"
          content="Tradingview Hub, Tradingview hub ng, Tradingview subscription for Nigeria traders, Trader tools for nigerians, Backtesttools, Backtesttools Nigeria, backtesttools tradingview hub"
        />{" "}
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
        />
        <meta property="og:title" content="Tradingview Hub Nigeria" />
        <meta
          property="og:description"
          content="We are dedicated to leveling the playing field for every trader out
            there"
        />
        <meta property="og:url" content="https://www.tradingviewhubng.com" />
        <meta property="og:site_name" content="Tradingview Hub Nigeria" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dysjhieis/image/upload/v1724447561/tradingviewhubng.com__3_bt0y1y.png"
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="600" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dysjhieis/image/upload/v1724447561/tradingviewhubng.com__3_bt0y1y.png"
        />
        <meta property="og:image:width" content="1800" />
        <meta property="og:image:height" content="1600" />
        <meta property="og:image:alt" content="Tradingview Hub Nigeria" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site:id" content="1738592585050894663" />
        <meta name="twitter:creator" content="@BacktestTools" />
        <meta name="twitter:title" content="Tradingview Hub Nigeria" />
        <meta
          name="twitter:description"
          content="We are dedicated to leveling the playing field for every trader out
            there"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dysjhieis/image/upload/v1724448122/backtesttools_tbweqv.png"
        />
        <meta name="twitter:image:alt" content="Tradingview Hub Nigeria" />
        <meta property="og:type" content="profile" />
        <meta property="og:site_name" content="Instagram" />
        <meta property="og:title" content="@backtesttools" />
        <meta
          property="og:url"
          content="https://www.instagram.com/backtesttools/"
        />
        <meta
          property="og:image"
          content="https://www.instagram.com/p/C40Htl_sDvM/"
        />
        <meta property="og:type" content="video.other" />
        <meta property="og:site_name" content="TikTok" />
        <meta
          property="og:title"
          content="backtesttools
"
        />
        <meta
          property="og:url"
          content="https://www.tiktok.com/@backtesttools"
        />
        <meta
          property="og:image"
          content="https://www.tiktok.com/@backtesttools/video/7399605219836939526?_r=1&_t=8oM0V85Azt0"
        />
      </Helmet>

      <div className="headsect">
        <div className="header">
          <div className="lctn">
            <a href="/">
              <img
                className="website-logo"
                src={thnlogo}
                alt="Tradingview Hub Nigeria"
              />
            </a>
          </div>
          <div className="lks-v" ref={dropdownRef}>
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              <img
                src={isDropdownOpen ? cancelMenu : showMenu}
                alt={isDropdownOpen ? "Close Menu" : "Open Menu"}
              />
            </button>
            <div className={`lks ${isDropdownOpen ? "show" : ""}`}>
              <a href="/" onClick={closeDropdown}>
                Home
              </a>
              <a onClick={closeDropdown} href="#about">
                About us
              </a>
              <a onClick={closeDropdown} href="#co-sponsoring">
                Co-sponsoring
              </a>
              <a onClick={closeDropdown} href="#plans">
                Plans
              </a>
            </div>
          </div>
        </div>
        <div className="hr-sec">
          <h1 className="min">Bridging the financial gap</h1>
          <p className="min-ph">
            We are dedicated to leveling the playing field for every trader out
            there
          </p>
        </div>

        <div className="cta">
          <div>
            <a href="#plans">Get Started </a>
          </div>{" "}
        </div>

        <div className="il-v">
          <img src={trade} alt="" />
        </div>
      </div>
      <div id="about" className="msn-sect">
        <About />
      </div>
      <div id="co-sponsoring" className="co-sect">
        <CoSponsor />
      </div>
      <div id="plans" className="pr-sect">
        <Pricing />
      </div>

      <div className="rv-sect">
        <Review />
      </div>
      <div className="f-st">
        <Footer />
      </div>
    </>
  );
}

export default Home;
